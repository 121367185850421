<template>
  <v-container
      id="panels"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">

        <base-material-card color="success" icon="mdi-card-account-details" title="My Account">
          <v-row no-gutters>
            <v-col cols="12" md="12">

              <template v-if="!user">
                <v-progress-linear
                    indeterminate
                    color="primary"
                ></v-progress-linear>
                <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
              </template>
              <template v-else>
                <v-tabs right>
                  <v-tab>
                    <v-icon class="mr-1">mdi-account</v-icon>
                    Profile
                  </v-tab>
                  <v-tab>
                    <v-icon class="mr-1">mdi-lock</v-icon>
                    Password
                  </v-tab>
                  <v-tab>
                    <v-icon class="mr-1">mdi-mail</v-icon>
                    Email
                  </v-tab>
                  <v-tab>
                    <v-icon class="mr-1">mdi-two-factor-authentication</v-icon>
                    MFA
                  </v-tab>
                  <v-tab-item>
                    <v-row>
                      <v-col cols="12" md="6">
                        <v-text-field
                            label="First Name"
                            v-model="user.firstName"
                            prepend-icon="mdi-account"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            label="Last Name"
                            v-model="user.lastName"
                            prepend-icon="mdi-account"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            label="Username"
                            v-model="user.username"
                            prepend-icon="mdi-account-details"
                        />
                      </v-col>

                      <v-col cols="12" md="6">
                        <v-text-field
                            label="email"
                            type="email"
                            v-model="user.email"
                            prepend-icon="mdi-mail"
                            disabled
                        />
                      </v-col>

                      <v-col cols="12">
                        <label class="v-label v-label--active theme--light">Profile</label>
                        <v-jsoneditor
                            class="py-2"
                            v-model="user.profile" :plus="true" :options="{mode: 'code'}" height="400px"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-spacer></v-spacer>
                        <v-btn color="success" class="text-right" small @click="checkProfileForm">
                          <v-icon small class="mr-1">mdi-content-save</v-icon>
                          Change
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row>
                      <v-col cols="6">
                        <v-col cols="12" md="12">
                          <v-text-field
                              label="Current password"
                              type="password"
                              prepend-icon="mdi-lock"
                              v-model="oldPassword"
                              hint="Enter your current password"
                          />
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-text-field
                              label="Password"
                              type="password"
                              prepend-icon="mdi-lock"
                              v-model="password"
                              hint="Enter a new password"
                          />
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-text-field
                              label="Confirm Password"
                              type="password"
                              prepend-icon="mdi-lock"
                              v-model="passwordConfirmation"
                              hint="Confirm the new password"
                          />
                        </v-col>
                      </v-col>
                      <v-col cols="6">
                        <v-alert
                            class="pa-1 pl-5"
                            type="info"
                            outlined
                            border="left">
                          The Password requires at least one digit.
                        </v-alert>
                        <v-alert
                            class="pa-1 pl-5"
                            type="info"
                            outlined
                            border="left">
                          The Password requires at least one lowercase letter.
                        </v-alert>
                        <v-alert
                            class="pa-1 pl-5"
                            type="info"
                            outlined
                            border="left">
                          The Password requires at least one uppercase letter.
                        </v-alert>
                        <v-alert
                            class="pa-1 pl-5"
                            type="info"
                            outlined
                            border="left">
                          The Password requires at least one special character.
                        </v-alert>
                        <v-alert
                            class="pa-1 pl-5"
                            type="info"
                            outlined
                            border="left">
                          The Password should be between 10 and 32 characters.
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-spacer></v-spacer>
                        <v-btn color="success" class="text-right" small @click="checkPasswordForm">
                          <v-icon small class="mr-1">mdi-content-save</v-icon>
                          Update
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row>
                      <v-col cols="12" md="12">
                        <v-text-field
                            label="Email"
                            type="email"
                            v-model="email"
                            hint="Once you click update, a link will be sent to the new email you provided. Click on the link to complete the process."
                            prepend-icon="mdi-email"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-spacer></v-spacer>
                        <v-btn color="success" class="text-right" small @click="checkEmailForm">
                          <v-icon small class="mr-1">mdi-content-save</v-icon>
                          Change
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-row>
                      <v-col cols="12" md="12">
                        <div style="margin-left: 10px">

                          <v-select
                              label="MFA Security"
                              v-model="myMfa"
                              required
                              item-text="text"
                              item-value="value"
                              :items="mfaSettings"
                              hint="Specify whether or not to turn on Multi Factor Authentication."
                              persistent-hint
                          ></v-select>
                        </div>

                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="text-right">
                        <v-spacer></v-spacer>
                        <v-btn color="success" class="text-right" :disabled="originalMfa===myMfa"
                               small @click="switchMFA">
                          <v-icon small class="mr-1">mdi-content-save</v-icon>
                          Change
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs>
              </template>
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";
import fieldsMixins from "./components/fieldAccess";
import VJsoneditor from "v-jsoneditor";

const data = {
  myMfa: 'default',
  originalMfa: '',
  user: null,
  oldPassword: '',
  password: '',
  passwordConfirmation: '',
  email: '',
  mfa: {
    enabled: false
  }
};

const methods = {

  async getUser() {
    this.user = JSON.parse(JSON.stringify(this.$store.getters['auth/getUser']));
    const user = await this.getSendData({
      'url': '/user',
      'method': 'get'
    });
    this.user = user.user;

    if (this.user.security && this.user.security.hasOwnProperty('mfa')) {
      this.myMfa = this.user.security.mfa;
    }
    this.originalMfa = this._lodash.clone(this.myMfa);
  },

  checkPasswordForm: function (e) {
    if (!this.oldPassword) {
      this.pushMessage({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'Please enter your current password.'
      });
      return false;
    }

    if (!this.validPassword(this.password)) {
      this.pushMessage({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'The password should be at least 10 characters long and contain 1 uppercase letter, 1 lowercase letter, 1 special character and 1 digit.'
      });
      return false;
    }

    if (this.password !== this.passwordConfirmation) {
      this.pushMessage({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'The provided password and confirmation don\'t match.'
      });
      return false;
    }

    this.submitPasswordChange();
    e.preventDefault();
  },

  submitPasswordChange() {
    const self = this;
    self.getSendData({
      'url': '/user/password/change',
      'method': 'post',
      'headers': {
        'env': 'portal'
      },
      'params': {
        'old': self.oldPassword,
        'password': self.password,
        'confirmation': self.passwordConfirmation,
      }
    }).then(() => {
      this.pushMessage({
        type: 'success',
        title: 'Password Changed',
        text: 'You have successfully changed your password.'
      });
      //this.cancelPasswordForm();
    }).catch(() => {
      // already handled in getSendData
    });
  },

  checkEmailForm: function (e) {
    if (!this.validEmail(this.email)) {
      this.pushMessage({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'Please enter a valid email address.'
      });
      return false;
    }

    this.submitEmailChange();
    e.preventDefault();
  },

  submitEmailChange() {
    const self = this;
    self.getSendData({
      'url': '/user/email/change',
      'method': 'post',
      'headers': {
        'env': 'portal'
      },
      'params': {
        "auto_register_flow": this.user.auto_registration_flow,
        'email': self.email,
      }
    }).then(() => {
      this.pushMessage({
        type: 'success',
        title: 'Verification Email Sent',
        text: 'Please check your email inbox to verify the new email address.'
      });
      this.cancelEmailForm();
    }).catch(() => {
      // already handled in getSendData
    });
  },

  checkProfileForm(e) {
    if (!this.user.firstName || this.user.firstName.trim() === '') {
      this.pushMessage({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'Please enter your first name!'
      });
      return false;
    }

    if (!this.user.lastName || this.user.lastName.trim() === '') {
      this.pushMessage({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'Please enter your last name!'
      });
      return false;
    }

    if (!this.user.username || this.user.username.trim() === '') {
      this.pushMessage({
        'type': 'error',
        'title': 'Validation Error',
        'text': 'Please enter a username!'
      });
      return false;
    }

    this.submitProfileForm();
    e.preventDefault();
  },

  submitProfileForm() {
    const self = this;
    self.getSendData({
      'url': '/user',
      'method': 'put',
      'headers': {
        'env': 'portal'
      },
      'params': {
        'username': this.user.username,
        'firstName': this.user.firstName,
        'lastName': this.user.lastName,
        'profile': this.user.profile
      }
    }).then(() => {
      this.pushMessage({
        type: 'success',
        title: 'Profile Updated',
        text: 'Your profile information has been updated.'
      });

      this.getSendData({
        'url': '/user',
        'method': 'get'
      }).then(response => {
        this.$store.dispatch('auth/setUser', response);
      });

      setTimeout(() => {
        // this.getUser();
        this.user = JSON.parse(JSON.stringify(this.$store.getters['auth/getUser']));
      }, 1000);
    }).catch(() => {
      // already handled in getSendData
    });
  },

  switchMFA() {
    const self = this;

    self.getSendData({
      "method": "patch",
      "url": "/user/security",
      'headers': {
        'env': 'portal'
      },
      'params': {
        "username": self.user.username,
        "mfa": self.myMfa
      }
    }).then(() => {
      self.pushMessage({
        type: 'success',
        title: 'MFA Updated',
        text: 'Your profile information has been updated.'
      });

      setTimeout(() => {
        self.getSendData({
          'url': '/user',
          'method': 'get'
        }).then(response => {
          self.$store.dispatch('auth/setUser', response);
        });
      }, 4000);

    });
  },
};

export default {
  name: 'Profile',

  mixins: [globalMixins],

  components: {
    VJsoneditor
  },

  data: () => (data),

  async created() {
    this.getUser();
  },

  methods: methods
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

</style>
